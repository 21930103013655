<template>
  <div class="bg-white sm:bg-main-bg min-h-screen pb-12">
    <div
      class="px-4 md:px-8 py-8 font-medium max-w-6x1 sm:flex items-center justify-between"
    >
      <span class="logo text-primary text-3x1">
        <img src="/assets/images/LoanSpot-logo-alt.svg" alt="" class="" />
      </span>
      <div>
        <!-- <img src="/assets/images/LoanSpot-logo-alt.svg" /> -->
      </div>
    </div>
    <div v-if="!user.phoneNumberVerified" class="max-w-lg mx-auto mt-12 px-4 md:px-0">
      <div
        class="max-w-xl bg-white sm:border border-border sm:py-16 sm:px-20 sm:shadow-loan rounded-lg mx-auto sm:pt-12 sm:pb-8"
      >
        <h3
          class="pt-6 font-medium text-sm text-center text-title verify--title"
        >
          Verify your phone number
        </h3>
        <p class="text-sm text-sec-text text-center py-3 verify--text">
          Please enter the code sent to
          <!-- <span class="text-secondary">(+234) 802 345 678</span> -->
          <span class="text-primary" v-if="user.user">{{ user.user.phoneNumber }}</span>
        </p>
        <form @submit.prevent="goTo" class="mt-8">
          <div class="flex items-center justify-center input-wrap">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input "
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            />
          </div>
          <p
            v-if="timeLeft > 0"
            class="my-6 text-textColor text-sm text-center"
          >
            If you don’t recieve a code in
            <span class="text-primary font-bold">{{ formattedTimeLeft }}</span>
          </p>
          <template v-if="timeLeft === 0">
            <p class="my-6 text-textColor text-sm text-center">
              Click the button below to resend
            </p>
            <div class="flex justify-center">
              <a
                @click.prevent="resend"
                to="send"
                class="resend mb-6 text-primary border border-border font-medium py-2 px-4 rounded-lg m-auto inline-block"
              >
                Resend
              </a>
            </div>
          </template>
          <!-- <span class="whatsapp w-full h-14 border border-border mb-6 bg-main-bg text-primary rounded-lg text-sm flex items-center justify-center">Get code via WhatsApp</span> -->
          <Button @click="goTo" id="verify-btn" class="mt-6">
            <span id="spin" slot="spin" class="animate-spin hidden">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M13.75 7C13.75 10.7279 10.7279 13.75 7 13.75C3.27208 13.75 0.25 10.7279 0.25 7C0.25 3.27208 3.27208 0.25 7 0.25C10.7279 0.25 13.75 3.27208 13.75 7ZM1.6 7C1.6 9.98234 4.01766 12.4 7 12.4C9.98234 12.4 12.4 9.98234 12.4 7C12.4 4.01766 9.98234 1.6 7 1.6C4.01766 1.6 1.6 4.01766 1.6 7Z" fill="#EFEFEF"/>
                <path d="M7 13.075C7 13.4478 6.69704 13.7535 6.32611 13.7163C5.37081 13.6204 4.44421 13.3216 3.60954 12.8367C2.57975 12.2385 1.72655 11.3785 1.13655 10.344C0.546549 9.3095 0.240758 8.1373 0.250213 6.94641C0.259668 5.75552 0.584032 4.58833 1.19038 3.56331C1.79673 2.5383 2.66348 1.69195 3.70264 1.11018C4.74181 0.528418 5.91639 0.231942 7.10717 0.250851C8.29795 0.26976 9.46253 0.60338 10.4827 1.21785C11.3096 1.71589 12.0177 2.38405 12.5617 3.17514C12.773 3.48231 12.6509 3.89503 12.3242 4.07455C11.9975 4.25407 11.5904 4.13163 11.3715 3.82984C10.9474 3.24504 10.4089 2.74935 9.78616 2.37428C8.97003 1.8827 8.03836 1.61581 7.08574 1.60068C6.13311 1.58555 5.19345 1.82273 4.36211 2.28815C3.53078 2.75356 2.83738 3.43064 2.3523 4.25065C1.86723 5.07066 1.60773 6.00442 1.60017 6.95713C1.59261 7.90984 1.83724 8.8476 2.30924 9.67521C2.78124 10.5028 3.4638 11.1908 4.28763 11.6694C4.91623 12.0345 5.61 12.2678 6.32674 12.3579C6.69663 12.4043 7 12.7022 7 13.075Z" fill="white"/>
              </svg>
            </span>
            <span id="check" slot="check" class="hidden mr-1">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7713 10.7714 10.7712C11.7716 9.77096 12.3335 8.41441 12.3335 6.99992C12.3335 5.58543 11.7716 4.22888 10.7714 3.22868C9.77121 2.22849 8.41465 1.66659 7.00016 1.66659C5.58567 1.66659 4.22912 2.22849 3.22893 3.22868C2.22873 4.22888 1.66683 5.58543 1.66683 6.99992C1.66683 8.41441 2.22873 9.77096 3.22893 10.7712C4.22912 11.7713 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66659L3.50683 6.83792L4.4495 5.89525L6.3355 7.78125L10.1062 4.00992L11.0495 4.95259L6.3355 9.66659Z"
                  fill="white"
                />
              </svg>
            </span>
            <span id="cancel" slot="cancel" class="hidden mr-1">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM10 8.586L12.828 5.757L14.243 7.172L11.414 10L14.243 12.828L12.828 14.243L10 11.414L7.172 14.243L5.757 12.828L8.586 10L5.757 7.172L7.172 5.757L10 8.586Z"
                  fill="white"
                />
              </svg>
            </span>
            <span id="verify" slot="value"> Verify phone number </span>
          </Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from "../../components/shared/button";
  import { API_ROUTES } from "../../constants";
  import { mapState } from "vuex";
  import { ApiInstance } from "../../utils";
  import { initiatePhoneNumberVerification } from "../../utils/phone";

  export default {
    name: "VerifyPhone",
    components: {
      Button,
    },
    beforeMount() {
      ApiInstance.get('/user/profile', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth-token')}`
        }
      })
      .then(res => {
        if(res.data?.data.phoneNumberVerified) {
          return this.$router.push({ path: "/" });
        }
        this.user = {...res.data?.data};

        if(res.data?.data.user.id) {
          const res = initiatePhoneNumberVerification(this.user?.user.id);
          if (res.status === "success") {
            this.$store.commit("SET_PHONE_VERIFICATION_TTL", res.ttl);
          }
        }
      })
      // if (this.user.phoneNumberVerified) {
      //   console.log('hi')
      // }
    },
    computed: {
      ...mapState({
        // user: (state) => state.profile,
        verificationTTL: (state) => state.ttl,
      }),
      formattedTimeLeft() {
        const timeLeft = this.timeLeft;
        const minutes = Math.floor(timeLeft / 60);
        let seconds = timeLeft % 60;

        if (seconds < 10) {
          seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
      },

      timeLeft() {
        return this.verificationTTL - this.timePassed;
      },

      timeFraction() {
        const rawTimeFraction = this.timeLeft / this.verificationTTL;
        return (
          rawTimeFraction - (1 / this.verificationTTL) * (1 - rawTimeFraction)
        );
      },
      ifLoaded() {
        return this.loaded
      }
    },
    watch: {
      timeLeft(newValue) {
        if (newValue === 0) {
          this.onTimesUp();
        }
      },
      
    },
    data() {
      return {
        user: {},
        loaded: false,
        timePassed: 0,
        timerInterval: null,
        verified: {
          success: "Verified",
          failed: "Verification failed",
        },
        otpBody: {
          code: "",
          phoneNumber: "",
        },
        notification: {
          options: {
            success: {
              title: "",
              message: "",
              backgroundColor: "#27BD8B",
              position: "topRight",
              progressBarColor: "#ffffff",
              titleColor: "#ffffff",
              messageColor: "#ffffff",
              close: false,
              timeout: 4000,
            },
            error: {
              backgroundColor: "#F23204",
              position: "topRight",
              progressBarColor: "#ffffff",
              titleColor: "#ffffff",
              messageColor: "#ffffff",
              close: false,
              timeout: 4000,
              iconColor: "white",
            },
          },
        },
      };
    },
    mounted() {
      this.startTimer();
      if (this.loaded) {
        const res = initiatePhoneNumberVerification(this.user.user.id);
        if (res.status === "success") {
          this.$store.commit("SET_PHONE_VERIFICATION_TTL", res.ttl);
        }
      }
    },
    head: {
      title: function () {
        return {
          inner: "Loanspot",
          complement: "Verify phone number",
        };
      },
      meta: [
        {
          name: "description",
          content: "Loanspot phone verification",
        },
        {
          name: "application-name",
          content: "Loanspot",
        },
      ],
    },
    methods: {
      async resend() {
        this.$notie.alert({
          type: "success",
          text: "Verification code has been sent.",
          time: 2,
        });
        setTimeout(() => {
          return window.location.reload();
        }, 2000);
      },
      onTimesUp() {
        clearInterval(this.timerInterval);
      },
      startTimer() {
        this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
      },
      async handleOnComplete(value) {
        document.getElementById("verify-btn").classList.remove("bg-failed");
        document.getElementById("cancel").classList.add("hidden");
        document.getElementById("verify-btn").classList.add("bg-secondary");
        document.getElementById("spin").classList.remove("hidden");
        document.getElementById("verify").classList.add("hidden");
        const response = await ApiInstance.post(
          API_ROUTES.AUTH.VERIFICATION.PHONE_COMPLETE,
          {
            userId: this.user?.user?.id,
            code: value,
          }
        );
        if (response.status) {
          this.$notie.alert({
            type: "success",
            text: "Phone verification successful.",
            time: 4,
          });
          return this.$router.replace(
            this.$route.query.redirect ? this.$route.query.redirect : "/setup/complete"
          );
        } else {
          this.$notie.alert({
            type: "error",
            text: response.error?.message || "Request failed",
            time: 4,
          });

          document.getElementById("verify-btn").classList.remove("bg-secondary");
          document.getElementById("verify-btn").classList.add("bg-failed");
          document.getElementById("cancel").classList.remove("hidden");
          document.getElementById("spin").classList.add("hidden");
          document.getElementById("verify").classList.remove("hidden");
          document.getElementById("verify").innerText = this.verified.failed;
          this.$refs.otpInput.clearInput();
        }
      },
      handleOnChange() {},
      goTo(e) {
        e.preventDefault();
        this.handleOnComplete();
      },
    },
  };
</script>

<style lang="scss" >
.header-wrap {
  padding: 35px 10%;
}

@media (min-width: 1440px) {
  .header-wrap {
    max-width: 1440px !important;
    margin: auto;
  }
}

.logo {
  font-family: SohneKraftig, sans-serif;
  font-size: 1.5rem;
}

.form-wrap {
  padding: 0 10%;
}

.verify--register,
.whatsapp {
  font-family: SohneKraftig, sans-serif;
  font-size: 0.875rem;
}

.verify--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}

.verify--text {
  font-size: 0.875rem;
  width: 200px;
  margin: auto;
}

.resend {
  width: 109px;
  text-align: center;
}

.input-wrap {
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 380px;
  }
}

.otp-input {
  width: 10vw;
  height: 10vw;
  max-width: 48px;
  max-height: 48px;
  margin: 0 4px;
  background: #f9fafc;
  border: 1px solid rgba(94, 81, 81, 0.04);
  border-radius: 8px;
  text-align: center;
  color: #575a65;
  font-family: SohneKraftig, sans-serif;
  font-size: 1.25rem;
}

.otp-input:focus {
  border: 1px solid #1656c1;
  outline: none;
}

.otp-input::placeholder {
  content: "0";
  color: #a8b3d6;
}
</style>
